<script lang="ts">
  export let imgSrc: string;
  export let title: string;
  export let href: string;
  export let description: string;
  export let imgSize = "48px"; // Default image size
</script>

<a href={href}>
	<div class="text-start flex flex-col justify-start gap-2 bg-white rounded-xl p-8 shadow-sm z-10 relative">
		<div>
		    <img
				loading="lazy"
			    src={imgSrc}
			    alt={title}
			    class="max-w-[48px] max-h-[48px] object-cover rounded-md mr-4"
			    height={imgSize}
			    width={imgSize}
		    />
		</div>
	    <h6 class="text-slate-900 text-[1.3rem] font-semibold">{title}</h6>
	    <p class="text-gray-400 text-[1rem] font-medium">{description}</p>
	</div>
</a>
