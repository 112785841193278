<script>
  import Footer from "$lib/components/navigation/Footer.svelte";
  import Navbar from "$lib/components/navigation/Navbar.svelte";
  import CaseStudy from "$lib/components/section/case-study.svelte";
  import Features from "$lib/components/section/features.svelte";
  import Hero from "$lib/components/section/hero.svelte";
  import MunioSection from "$lib/components/section/munio-section.svelte";
  import OurServices from "$lib/components/section/our-services.svelte";
  import TellUsSection from "$lib/components/section/tellus.svelte";
  import QnA from "$lib/components/section/faq.svelte";
	import Testimonial from "$lib/components/section/testimonial.svelte";
</script>

<Navbar />
<Hero />
<div class="mx-4 lg:mx-12 2xl:mx-32">
  <CaseStudy />
</div>
<OurServices />
<Features />
<MunioSection />
<!-- <Testimonial />
<div class="bg-[#c12f28]">
  <div class="flex justify-center bg-primary items-center rounded-b-[2rem]">
    <div class="max-w-[95%] lg:max-w-[80%]">
      <QnA/>
    </div>
  </div>
</div> -->
<TellUsSection />
<Footer />
