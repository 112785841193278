<div class="min-h-screen bg-[#FFEFEE] mt-8 hero-content max-w-full rounded-b-xl">
  <section class="flex flex-col items-center rounded-b-xl">
    <h4 class="text-center text-lg lg:text-xl text-primary font-medium mb-3 font-['Inter'] capitalize leading-snug">INTEGRASI SEPERTI YANG ANDA INGINKAN</h4>

    <div class="hero-content text-center text-neutral-content">
      <div class="max-w-[1100px]">
        <p
          class="font-[Courgette] text-[#101828] text-[24px] lg:text-[48px] text-center font-[400] leading-normal lg:leading-[60px] tracking-[2%]">
          <span>
            Munio 
          </span>adalah
          <span>
            platform 
          </span> 
          <span>
            online 
          </span> 
          <br> untuk
          <span>
            membantu komunitas
          </span> mengelola
          <span>
            anggotanya
          </span>
          dengan fitur-fitur menarik.
        </p>
      </div>
    </div>
  </section>
</div>

<style>
  .min-h-screen {
    min-height: 50vh;
  }
  p {
    text-align: center;
    color: #993732;
  }
  span {
    color: #FF5C54;
  }
  /* .items-center {
    text-align: center; */
    /* padding-top: 5rem; */
  /* } */
</style>
