<script>
	import AccordionItem from "$lib/common/AccordionItem.svelte";

  import { onMount } from "svelte";

   const data = [
    {
      q: "Membership",
      a: "Pendataan keanggotaan komunitas mulai dari pendaftaran, pembayaran, dan verifikasi secara realtime.",
      c: true
    },
    {
      q: "News",
      a: "Konten publikasi berita komunitas yang dapat di filtrasi berdasarkan relevansi kebutuhan anggota.",
      c: true
    },
    {
      q: "Events",
      a: "Konten publikasi kegiatan komunitas untuk meningkatkan partisipasi anggota.",
      // c: true
    },
    {
      q: "Campaign",
      a: "Konten publikasi pengumpulan dana komunitas untuk meningkatkan upaya penggalangan dana dan kesukarelaan anggota dengan menghubungkan dan memberi energi pada basis dukungan mereka terhadap komunitas ataupun sesama.",
      // c: true
    },
  ];

  let data2 = [
    {
      q: "Member Management",
      a: "Pengelolaan keanggotaan komunitas secara efektif, efisien dan realtime.",
      c: true
    },
    {
      q: "Content Management",
      a: "Pengelolaan publikasi berita, acara, atau gerakan untuk kalangan tertentu.",
      c: true
    },
    {
      q: "Broadcast",
      a: "Pengiriman informasi berita, promosi acara, pemberitahuan informasi penting lansung ke anggota.",
      // c: true
    },
    {
      q: "Collect Online Payment",
      a: "Pengelolaan informasi pembayaran dari setiap kegiatan yang dimonetisasikan",
      // c: true
    },
  ];

  function updateDataFromURL() {
    const urlParams = new URLSearchParams(window.location.search);
    const selectedItem = urlParams.get('item');

    data2 = data2.map(item =>
      selectedItem === "online-payment" && item.q === "Collect Online Payment"
        ? { ...item, c: true } // update c: true jika cocok
        : item
    );
  }

  onMount(() => {
    // Initial update when the component mounts
    updateDataFromURL();

    // Listen to hashchange and popstate events to capture URL changes in realtime
    window.addEventListener("popstate", updateDataFromURL);
    window.addEventListener("hashchange", updateDataFromURL);

    return () => {
      // Cleanup event listeners when component is destroyed
      window.removeEventListener("popstate", updateDataFromURL);
      window.removeEventListener("hashchange", updateDataFromURL);
    };
  });

</script>

<div class="mx-auto w-[95%] lg:max-w-[80%]" id="features">
  <div class="h-12 flex flex-col justify-center items-center mt-[5rem] mb-1">
     <h4 class="text-primary text-[1rem]">FEATURES</h4>
    <p class="text-3xl text-slate-950 mt-2 text-center font-semibold">Temukan Aksesibilitas yang Mulus</p>
  </div>

  <div class="flex flex-col md:flex-row md:gap-4 lg:mb-5 justify-start items-center w-full ">
    <div class="flex flex-col justify-end items-end">
      <picture>
        <img
          sizes="(max-width: 1400px) 100vw, 1400px"
          src="/images/features/features-1.webp"
          loading="lazy"
          alt="Gambar"
          class="object-contain community-object xl:max-w-[670px] lg::max-w-[472px] h-[32rem] my-12"
          width="697px"
          height="596px"
        />
      </picture>
    </div>
    <div class="flex-col justify-start items-start -mt-16 lg:mt-2 w-full md:max-w-[80%]">
        {#each data as { q, a, c }}
          <AccordionItem entry={{ title: q, description: a }} isFirst={c} />
        {/each}
      </div>
  </div>

  <div class="flex flex-col-reverse md:flex-row gap-4 mb-5 justify-start items-center" id="features2">
      <div class="flex-col justify-end justify-self-end -mt-16 lg:mt-2 w-full md:max-w-[80%]">
        {#each data2 as { q, a, c }}
          <AccordionItem entry={{ title: q, description: a }} isFirst={c} />
        {/each}
      </div>
      <div class="flex flex-col justify-start items-start">
        <picture>
          <img
            sizes="(max-width: 1400px) 100vw, 1400px"
            src="/images/features/features-2.webp"
            alt="Gambar"
            loading="lazy"
            class="object-contain community-object xl:max-w-[670px] lg::max-w-[472px] h-[32rem] my-12"
            width="697px"
            height="596px"
          />
        </picture>
      </div>
  </div>

</div>

<style>
</style>