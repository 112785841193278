<script>
  import { slide } from "svelte/transition";
  import { onMount } from "svelte";

  export let entry;
  export let isFirst = false; // Menandakan elemen pertama
  const { title, description } = entry;
  let isOpen = false;

  // Saat komponen dimuat, buka elemen pertama
  onMount(() => {
    if (isFirst) {
      isOpen = true;
    }
  });

  const toggle = () => (isOpen = !isOpen);
</script>

<button class="flex justify-between items-center w-full" on:click={toggle} aria-expanded={isOpen}>
  	<span class="text-xl">{title}</span>
	<svg
	    class="mx-2"
	    width="20"
	    height="20"
	    fill="none"
	    stroke-linecap="round"
	    stroke-linejoin="round"
	    stroke-width="2"
	    viewBox="0 0 24 24"
	    stroke="currentColor"><path d="M9 5l7 7-7 7" /></svg
	>
</button>

<div class="ml-6 mb-4 pb-4 border-b-2 border-b-gray-200">
  {#key isOpen}
    <p class:hidden={!isOpen} transition:slide={{ duration: 300 }}>
      {@html description}
    </p>
  {/key}
</div>

<style>
	button {
	    border: none;
	    background: none;
	    color: inherit;
	    cursor: pointer;
	    margin: 0;
	    padding-bottom: 0.5rem;
	    padding-top: 0.5rem;
		color: black;
	}

	  	svg {
			flex-shrink: 0;
	    	transition: transform 0.2s ease-in;
	  	}

		[aria-expanded="true"] svg {
		    transform: rotate(0.25turn);
		}
	
	p {
		margin: 0;
		margin-left: 0rem;
		color: rgb(148, 142, 142);
	}
	.flex {
		display: flex;
	}

	.ml-6 {
		margin-left: 0rem;
	}
	.mb-4 {
		margin-bottom: 1rem;
	}
	.hidden {
		display: none;
	}
</style>
