<script>
    import { Swiper, SwiperSlide } from "swiper/svelte";
    import { Pagination } from "swiper";
    import '@splidejs/svelte-splide/css/core';
</script>

<div class="w-full lg:mt-4 py-8" style="border-radius: 16px;">
  <div class="px-12 flex flex-col lg:flex-row justify-between items-center gap-8 md:gap-0">
    <div class="text-[20px] md:text-[24px] lg:text-[30px] mb-10 font-normal text-center lg:text-start">
      <h4 class="text-primary text-[1rem]">OUR CLIENTS</h4>
      <p class="text-[1.5rem] text-slate-950">Mereka yang Percaya Kami</p>
    </div>
    <div class="-mb-4 w-full lg:w-[60%] xl:w-[60%]">
      <Swiper
        freeMode={true}
        grabCursor={true}
        pagination={{
    		  clickable: true, // This makes pagination bullets clickable
    		}}
        modules={[Pagination]}
        breakpoints={{
          "240": {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          "480": {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          "640": {
            slidesPerView: 3,
            spaceBetween: 12,
          },
          "768": {
            slidesPerView: 4,
            spaceBetween: 5,
          }
        }}
        class="mySwiper">
        <SwiperSlide>
          <div class="flex flex-row justify-center items-center">
            <a href="https://katetigama.id/" target="_blank">
              <img
                class="h-[45px] mt-2"
                loading="lazy"
                src="katetigama.webp"
                alt="casestudy- 4"
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="flex flex-row justify-center items-center">
            <a href="https://perpesi.org" target="_blank">
              <picture>
                <img
                  class="h-[45px] mt-2"
                  loading="lazy"
                  alt="casestudy- 2"
                  src="perpesi.webp"
                />
              </picture>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="flex flex-row justify-center items-center">
            <a href="https://catchmeup.co/" target="_blank">
              <img
                class="h-[55px]"
                loading="lazy"
                src="cmu.webp"
                alt="cmu"
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="flex flex-row justify-center items-center">
            <a href="https://cretaid.munio.id/" target="_blank">
              <img
                class="h-[55px]"
                loading="lazy"
                src="creta.webp"
                alt="casestudy- 3"
              />
            </a>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</div>